/* eslint-disable react/destructuring-assignment */
/* eslint react/prop-types: 0 */

// Components
import React, { Component } from 'react';
import { graphql } from 'gatsby';
import 'gitalk/dist/gitalk.css';
import Disqus from 'gatsby-plugin-disqus';
import { parseChineseDate } from '../api';
import Sidebar from '../components/Sidebar';
import Content from '../components/Content';
import SEO from '../components/SEO';
import Header from '../components/Header';
import ShareBox from '../components/ShareBox';
import { config } from '../../data';

// Styles
import './blog-post.scss';

const { name, iconUrl, url } = config;

class BlogPost extends Component {
  constructor(props) {
    super(props);
    this.data = this.props.data;
  }


  render() {
    const { node } = this.data.content.edges[0];

    const {
      html, frontmatter, fields, excerpt,
    } = node;

    const { slug } = fields;

    const {
      date, headerImage, title, id,
    } = frontmatter;

    return (
      <div className="postpage">
        <div className="container">
          <div className="row post order-2">

            <div className="col-xl-9 col-lg-9 col-md-12 col-xs-12 order-2 ">
              <div className="content postdata">

                <Header
                  img={headerImage || ''}
                  title={title}
                  authorName={name}
                  authorImage={iconUrl}
                  subTitle={parseChineseDate(date)}
                />
                <Content post={html} />

                <Disqus
                  identifier={id}
                  title={title}
                  url={`${url}${slug}`}
                />
              </div>
            </div>
            <Sidebar />
            <SEO
              title={title}
              url={slug}
              siteTitleAlt="Hitesh Vaghasiya's Blog"
              isPost={false}
              description={excerpt}
              image={headerImage || '/assets/default/default.jpg'}
            />
          </div>
        </div>
        <ShareBox url={slug} />
      </div>
    );
  }
}

export const pageQuery = graphql`
  fragment post on MarkdownRemark {
    fields {
      slug
    }
    frontmatter {
      id
      title
      slug
      date
      headerImage
    }
  }

  query BlogPostQuery($index: Int) {
    content: allMarkdownRemark(
      sort: { order: DESC, fields: frontmatter___date }
      skip: $index
      limit: 1
    ) {
      edges {
        node {
          id
          html
          tableOfContents
          excerpt
          ...post
        }

        previous {
          ...post
        }

        next {
          ...post
        }
      }
    }
  }
`;

export default BlogPost;
